import React from 'react';

export default {
  pages: {
    floorplans: '플로우플랜',
    interiors: '인테리어',
    features: '주요 특징',
    neighbourhood: '지역 환경',
    gallery: '갤러리',
    developer: '개발사',
    register: '등록하기',
  },
  bookAppt: '지금 사전 예약하세요',
  contact: '연락하기',
  home: {
    nowSelling: '이제 판매 중이며, 남은 집은 네 채뿐입니다.',
    banner1: {
      title: '시그니처 컬렉션',
      description:
        'CEDAR CREEK의 마지막 단지는 신선하고 현대적인 마감으로 한층 신중하게 처리되었습니다. 1 베드룸 & 덴에서 3 베드룸의 평형까지 다양한 면적의 주택으로 구성된 이 대표적인 시그니처 컬렉션은 입주자 가족들의 구성원 여부와 상관없이 다양한 라이프 스타일에 최적화된 단지입니다. 바로 고향과 같은 곳입니다.',
      buttonLabel: '플로우플랜 보기',
    },
    banner2: {
      title: '자연의 고요함, 고향과 같은 편안함',
      description: (
        <>
          견고하고 장엄한 브리티시 컬럼비아의 삼나무(CEDAR)에서 영감을 받은
          LEDINGHAM MCALLISTER의 시그니처 컬렉션은 버나비의 에드먼즈 지역
          중심부에서 현대적 감각의 웨스트 코스트풍의 디자인과 편리한 생활 환경을
          제공하는 주거단지에 오신 모든 분들을 환영합니다.
          <br />
          <br />
          CEDAR CREEK은 높은 천장과 밝은 개방형 컨셉트가 살아 있으며 생활을
          야외공간으로 확장시켜 주는 멋진 목재 프레임 주택입니다.
        </>
      ),
      buttonLabel: '인테리어 보기',
    },
    banner3: {
      title: '시야를 확장시키십시오',
      description:
        'CEDAR CREEK 의 입주자들은 인상적인 입주자 전용 실내 및 실외 편의시설을 개인적으로 이용할 수 있습니다. CEDAR CREEK은 외부로 차를 타고 다녀와야 하는 불편함 없이 좋아할만한 모든 것들을 단지 내에서 즐길 수 있도록 각종 편의시설을 제공합니다.',
      buttonLabel: '주요 특징 살펴보기',
    },
    banner4: {
      title: '버나비의 자연',
      description:
        'CEDAR CREEK의 시그니처 컬렉션은 입주자들을 다양한 야외 활동을 위한 산책로와 공원, 호수 및 숲으로 어우러진 버나비의 광대한 자연으로 안내할 것입니다. 가벼운 산책, 활기찬 자전거 탐험, 부드러운 카누 여행 등 어떤 것이든 즐기기를 원하는 모든 것을 누리기에 부족함이 없을 것입니다.',
      buttonLabel: '주변 환경',
    },
    backHome: 'back to homepage',
  },
  floorplans: {
    header: {
      plan: '플랜',
      type: '타입',
      sqft: '평형',
    },
    welcome: {
      title: '집에 오신 것을 환영합니다',
      body: '1베드룸 + 덴에서 3 베드룸 주택에 이르기까지 다양한 스타일, 형태 및 기능이 결합되어 있어 입주자의 주거 만족감을 극대화시켜  드립니다.',
    },
    types: {
      oneBedOneDen: '1베드룸 & 덴',
      twoBed: '2 베드룸',
      threeBed: '3 베드룸',
    },
    downloadPlan: 'Download Plan',
  },
  interiors: {
    hero: {
      title: '입주자들의 취향에 맞춘 개인 홈',
      body: '높은 천장과 넓은 창문은 일조량을 최대화하고 탁트인 거실 공간을 제공하여 입주자들 개개인의 라이프 스타일에 맞는 분위기를 연출해 줍니다. 입주자들을 위한 이러한 꼼꼼한 배려는 입주자들에게 편안한 안식처를 제공해 줍니다.',
    },
    banner1: {
      title: '주택의 중심',
      body: '최고의 모임은 대개 에피타이저에서 풀코스 식사에 이르기까지 식사를 중심으로 이루어집니다. 주방은 바로 이러한 엔터테인먼트를 위해 디자인되었습니다. 대부분의 세대에는 식료품으로 가득 채울 팬트리가 설치되어 있으며 주방 아일랜드와 아침 식사 바는 음식 준비나 캐주얼한 식사에 매우 적합합니다. 주방시설은 빈틈이 없는 완벽함을 제공해 드립니다. 바로 이 곳에서 입주자들은 오래도록 간직하고 싶은 추억을 만들게 될 것입니다.',
    },
    banner2: {
      title: '주택 소유자들의 주거 자부심',
      body: (
        <>
          <li>
            수상 경력에 빛나는 INTEGRA ARCHITECTURE 가 설계한 웨스트 코스트풍의
            현대적인 주거단지
          </li>
          <li>
            PORTICO DESIGN GROUP에서 엄선한 두 가지 디자이너 팔레트 중 선택 가능
          </li>
          <li>
            채광과 생활의 흐름을 극대화한 통풍이 잘 되는 9 피트 높이의 천장
          </li>
          <li>풍성하고 시원스럽게 펼쳐진 조경과 정원들</li>
          <li>
            입주자들이 거주하는 동안 걱정없이 생활할 수 있도록 철저한 관리와
            서비스를 제공해 주는 고객 전담 서비스 팀
          </li>
        </>
      ),
      buttonLabel: '주요 특징 시트 다운로드하기',
    },
  },
  features: {
    hero: {
      title: '생활의 반경이 넓어집니다',
      body: 'CEDAR CREEK은 입주자들이 외부 시설을 이용하는 불편함을 겪지 않고 필요한 모든 실내,외 편의 시설을 단지 내에서 마음껏 이용할 수 있습니다. 체력 단련이 목표이든, 친구들을 접대하며 가족들과 함께 즐거운 시간을 가지든, 하룻밤 묵어 가는 방문객을 맞이하든 입주자들에게 필요한 모든 제반 시설들을 완벽하게 제공해 줍니다.',
    },
    banner1: {
      title: '일년 내내 즐길 수 있는 넓은 야외 공간',
    },
    banner2: {
      body: '개인 야외 공간에서 휴식을 취하거나 탁트인 커뮤니티의 야외 공간에서 휴식을 취하든 CEDAR CREEK의 입주자 전용 편의 시설은 생활 공간을 아름다운 야외로 연결시켜 줍니다.',
      buttonLabel: '주요 특징 시트 다운로드하기',
    },
    landscape: {
      title: '조경 계획',
      labels: {
        entrance: '출입구',
        bikeRacks: '자전거 RACKS',
        parkadeEntrance: ' 주차장 입구',
        childrensPlayArea: ' 어린이 놀이터',
        picnicTables: '피크닉 테이블',
        gardenPlots: '작은 정원들',
      },
    },
  },
  neighbourhood: {
    hero: {
      title: '버나비의 자연',
      body: 'CEDAR CREEK은 입주자들을 다양한 야외 활동을 위한 산책로와 공원, 호수 및 숲으로 어우러진 버나비의 광대한 자연으로 이어지는 곳으로 안내할 것입니다.',
    },
    banner1: {
      title: '버나비의 에드몬즈 지역 중심에서 누리는 편리한 생활',
    },
    banner2: {
      body: '편리한 주거 지역의 중심부에 자리 잡고 있는 CEDAR CREEK은 버나비의 다채로운 부티크 상점, 엔터테인먼트, 현대적인 레크리에이션 및 대중교통 등 모든 편리한 생활 편의시설이 도보 거리 내에 위치해 있습니다. CEDAR CREEK은 대도시의 모든 편의 시설을 두루 갖춘 신선하고 새로운 커뮤니티를 제공합니다.',
    },
    map: {
      categories: {
        parks: '공원 & 레크레이션',
        restaurants: '음식점 & 카페들',
        education: '교육 시설',
        shopping: '쇼핑',
        fitness: '피트니스',
        transit: '대중교통',
      },
    },
  },
  gallery: {
    hero: {
      title: '웨스트 코스트풍의 감동',
      caption: 'Cedar Creek 시그니처 컬렉션 2차 분양 단지',
      body: '주거 단지에 도착함과 동시에 건축학적으로 세심하게 주의를 기울인 디테일에 감동을 받게 될 것입니다.  그 감동은 풍성하게 우거진 정원에서부터 벽돌과 목재 디테일이 살아있는 웅장한 입구를 지나 벽난로 좌석이 있는 높은 천장의 로비에 이르면 절정에 다다르게 됩니다. CEDAR CREEK 의 모든 것들은 마치 고향 집과 같은 편안함을 느끼도록 고려되었습니다.',
    },
    banner1: {
      title: 'Interiors',
      captions: {
        kitchen: 'kitchen',
        dining: 'dining',
        living: 'living',
        office: 'office',
        bedroom: 'bedroom',
        bathroom: 'bathroom',
      },
    },
    banner2: {
      title: 'Neighbourhood',
    },
  },
  developer: {
    hero: {
      title: '믿음. 품질. 가치',
      body: 'LEDINGHAM MCALLISTER는 좋은 위치에 우수한 새로운 주거 커뮤니티를 조성하는 우아하고 세련된 주택 개발에 전념하고 있습니다. 지난 한 세기 동안 오래도록 지속되는 품질과 가치를 보장하는 주택 건설을 위해 설계는 물론 환경 리더십, 엔지니어링 및 엄격한 건설 표준 준수 등 최선을 다하겠다는 스스로의 약속을 철저히 지켜 왔습니다. 향후에 18,000세대 이상의 주택이 건설되고 나면 우리는 우리의 과거가 그 위에 건설되었으며 우리의 미래 역시 그것에 의해 만들어 질 것이라고 자랑스럽게 말할 수 있을 것입니다.',
    },
    companies: {
      integra: {
        department: '설계',
        description: (
          <>
            Integra Architecture Inc.는 밴쿠버 중심부에 위치한 수상 경력에
            빛나는 건축 회사입니다. 이 회사는 강력한 고객 관계, 효과적인 의사
            소통 및 고품질의 지속 가능한 건축 설계를 매우 중요하게 생각하는 재능
            있고 앞서가는 전문가 팀을 보유하고 있습니다. 다양한 포트폴리오에는
            모든 유형의 주거용 건물, 복합 용도 프로젝트 및 노인 주택이
            포함됩니다.
            <br />
            <br />
            커뮤니케이션과 고품질 건축 도면을 통해 Integra Architecture Inc. 는
            고객의 현실과 건축가의 비전 사이의 차이를 매끄럽게 연결해 줍니다.
          </>
        ),
      },
      portico: {
        department: '인테리어 디자인',
        description: (
          <>
            우리의 접근 방식은 항상 "공간에 생명을 불어넣는" 열망에 의해
            주도되어 왔습니다. 새로운 발견의 센스를 통하여 드라마틱한 인테리어를
            창조함으로써 사람들이 매일 사용하는 공간에 참여하고 상호 작용하게
            합니다. 우리는 건축, 조경, 지리 및 관련된 사람들과 동일한 프로젝트
            비전에 충실하도록 인테리어를 디자인합니다. 우리는 사람들의 기대에
            도전하고 그 이상의 것을 달성하는 것을 좋아합니다.
            <br />
            <br />
            1992년에 설립된 우리는 상업 및 주거 고객, 리조트 운영자, 주거 및
            상업용 부동산 개발업자 모두에게 인테리어 디자인 서비스를 제공하고
            있습니다.
          </>
        ),
      },
      prospect: {
        department: '조경 설계',
        description:
          'Prospect & Refuge는 BC 주 밴쿠버에 본사를 둔 종합 조경 건축 회사입니다. 다가구 주택에서 학교, 보육 시설, 병원 및 산업 현장에 이르기까지 지역 사회와 협력하여 활기차고 기억에 남는 장소를 만들기 위해  고객과 함께 최선을 다하여 왔습니다. Jonathan Losee Ltd.로서의 25년 이상의 역사를 바탕으로 Prospect & Refuge는 유머 감각, 고객 중심 서비스, 환경 중심, 세부 사항에 대한 기술적 관심이라는 Jon의 유업을 지속적으로 이어가고 있습니다.',
      },
    },
  },
  footer: {
    column1: {
      title: '연락하기',
      buttonLabel: '지금 등록하세요!',
    },
    column2: {
      title: '프레젠테이션 센터',
      text: (
        <>
          예약을 위해 전화하세요.
          <br />
          7166 14th Ave, 버나비, BC
        </>
      ),
      buttonLabel: '지금 사전 예약하세요',
    },
    column3: {
      title: '현장 위치',
    },
    marketing: 'marketing in association with',
    disclaimer:
      'The information contained in this promotional material is for illustrative purposes only and is subject to change without notice. Maps and renderings are an artistic representation only. The quality residences at Cedar Creek 2 are developed by LM Cedar Creek Homes Limited Partnership. The developer reserves the right to make modifications or substitutions should they be necessary. This is currently not an offering for sale. Any such offering can only be made by way of a disclosure statement. E. & O.E.',
    corporate: {
      title: '믿음. 품질. 가치',
      text: 'At Ledingham McAllister, we are dedicated to creating quality homes in superior locations and providing our customers with long-term value. We are committed to the highest standards in everything we do. Our past was built on it, our future depends on it.',
      privacy: '개인정보 정책',
    },
  },
  register: {
    title: '지금 등록하세요',
    firstname: '이름*',
    lastname: '성*',
    email: '이메일*',
    phone: '전화번호*',
    zip: '우편번호*',
    select: '하나를 선택해 주세요',
    howHeard: 'Cedar Creek에 대해 어떻게 알게 되셨습니까?*',
    howHeardOptions: [
      {
        label: '온라인 광고',
        value: 'Online Ads',
      },
      {
        label: '중국어 광고',
        value: 'Chinese Ads',
      },
      {
        label: '한국어 광고',
        value: 'Korean Ads',
      },
      {
        label: '인쇄 광고',
        value: 'Print Advertising',
      },
      {
        label: '소셜 미디어',
        value: 'Social Media',
      },
      {
        label: '간판',
        value: 'Signage',
      },
      {
        label: '리얼터',
        value: 'Realtor',
      },
      {
        label: '친구 & 가족',
        value: 'Friend & Family',
      },
      {
        label: '기타',
        value: 'Other',
      },
    ],
    whatInquire: '궁금하신 사항이 있으신가요?',
    whatInquireOptions: [
      {
        label: '1 베드룸',
        value: 'One bedroom',
      },
      {
        label: '2 베드룸',
        value: 'Two bedroom',
      },
      {
        label: '3 베드룸',
        value: 'Three bedroom',
      },
      {
        label: '펜트 하우스',
        value: 'Penthouses',
      },
    ],
    realtor: '부동산 중개인이신가요?*',
    workingWithRealtor: '부동산 중개인과 함께 일하시나요?',
    yes: '예',
    no: '아니요',
    ifYesRealtor: `만약 부동산 중개인이시거나 부동산 중개인과 함께 일하신다면 이름을 알려 주세요.`,
    disclaimer:
      '향후 Ledingham McAllister가 제공하는 분양 정보, 우선 분양권및 특별 프로모션 등에 대한 정보를 이메일 등을 통하여 수신하는것에 동의합니다.*',
    submit: '제출하기',
    required: '*필수적으로 기록해야 하는 사항',
  },
  thankYou: {
    title: '감사합니다.',
    body: 'LEDINGHAM MCALLISTER의 시그니처 컬렉션에 등록해 주셔서 감사합니다. 귀하께서 보내 주신 내용이 접수되었으며 담당자가 곧 연락을 드릴 것입니다.',
  },
  notFound: {
    title: (
      <>
        Sorry! <br />
        We apologize
      </>
    ),
    subtitle: '404 Not Found',
    body: (
      <>
        Looks like something went wrong here. <br />
        The page you are looking for does not exist.
      </>
    ),
  },
  privacy: {
    title: '개인정보 정책',
  },
};
